<template>
    <div>
        <template v-if="!items.length">
            <p class="subtitle-1 secondary--text py-5 text-center"><v-icon color="primary" class="mr-1">fa fa-info-circle</v-icon> You haven't triggered any alerts yet.</p>
        </template>
        <template v-else>
                <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="10"
                class="elevation-1"
                :expanded.sync="expanded"
                item-key="uuid"
                :single-expand="true"
                mobile-breakpoint="10"
            >
                    <template v-slot:item="{ item, expand, isExpanded }">
                        <tr class="hover-pointer tr-fade-leave-active">
                            <td @click="expand(!isExpanded)" class="font-weight-medium">
                                {{ $moment.utc(item.created_at).tz(user.timezone).format('MMM Do YYYY') }}
                            </td>
                            <td class="text-left" @click="expand(!isExpanded)">
                                <v-chip small v-if="item.status==1" color="error">Active</v-chip>
                                <span v-else>{{ $moment.utc(item.upated_at).tz(user.timezone).format('MMM Do YYYY') }}</span>
                            </td>
                            <td class="hidden-sm-and-down text-center" @click="expand(!isExpanded)">{{ item.alertviews.length }}</td>
                            <td class="text-center hidden-sm-and-down" @click="expand(!isExpanded)">{{ item.views }}</td>
                            <td class="text-right">
                                <v-btn icon @click="expand(!isExpanded)"><v-icon>fa fa-chevron-circle-{{ (isExpanded ? 'up' : 'down')}}</v-icon></v-btn>
                            </td>
                        </tr>
                    </template>
                        <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" style="border-bottom: 1px solid #ccc;">
                            <div class="body-2 py-3 px-4 grey lighten-4">
                                <p class="mb-2 font-weight-medium hidden-md-and-up"><v-icon small class="pr-1">fa fa-eye</v-icon> 
                                    <template v-if="item.views > 0">
                                        This alert was viewed {{ item.views }} time{{ (item.views == 1) ? '' : 's' }}.
                                    </template>
                                    <template v-else>
                                        This alert was not viewed by anyone.
                                    </template>
                                </p>
                                <p class="mb-0 font-weight-medium"><v-icon small class="pr-1">fa fa-users</v-icon> 
                                    <template v-if="item.alertviews.length">
                                        {{ item.alertviews.length }} of your <span class="hidden-sm-and-down pr-1">emergency</span> contacts responded to this alert:
                                    </template>
                                    <template v-else>
                                        This alert was not viewed by anyone.
                                    </template>
                                </p>
                                
                                <v-list three-line class="py-0 transparent">
                                    <template v-for="contact in item.alertviews">
                                        <v-list-item :key="contact.uuid" class="pl-0" style="border-color: #ccc;">
                                            <v-list-item-avatar size="65" class="hidden-sm-and-down">
                                                <v-avatar color="primary" size="80">
                                                    <span class="display-1 font-weight-bold white--text">{{ contact.name[0] }}</span>
                                                </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title><b>{{ contact.name }}</b> &bull; {{ contact.phone }}</v-list-item-title>
                                                <v-list-item-subtitle>Viewed {{ $moment.utc(contact.created_at).tz(user.timezone).format('MMM Do YYYY [at] h:mma') }}.</v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    <template v-if="contact.authd">
                                                        <v-icon small color="primary">fa fa-eye</v-icon> Viewed your emergency notes.
                                                    </template>
                                                    <template v-else>
                                                        <v-icon small>fa fa-eye-slash</v-icon> Did not view your emergency notes.
                                                    </template>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item :key="contact.uuid + '1'" class="pl-0">
                                            <v-list-item-avatar size="65" class="hidden-sm-and-down">
                                                <v-avatar color="primary" size="80">
                                                    <span class="display-1 font-weight-bold white--text">{{ contact.name[0] }}</span>
                                                </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title><b>{{ contact.name }}</b> &bull; {{ contact.phone }}</v-list-item-title>
                                                <v-list-item-subtitle>Viewed {{ $moment.utc(contact.created_at).tz(user.timezone).format('MMM Do YYYY [at] h:mma') }}.</v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    <template v-if="contact.authd">
                                                        <v-icon small color="primary">fa fa-eye</v-icon> Viewed your emergency notes.
                                                    </template>
                                                    <template v-else>
                                                        <v-icon small>fa fa-eye-slash</v-icon> Did not view your emergency notes.
                                                    </template>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </div>
                        </td>
                        </template>
            </v-data-table>
        </template>
    </div>
</template>
<script>
    
import { mapState } from 'vuex'

export default {
    name: 'UserActivityAlerts',
    props: {
        items: {
            required: true,
            type: Array
        }
    },
    components: {
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        expanded: [],
        headers: [
          {
            text: 'Alert Date',
            align: 'start',
            sortable: true,
            value: 'created_at',
          },
          { text: 'Alert Resolved', sortable: true, value: 'updated_at', align: 'left' },
          { text: 'Responders', sortable: false, value: 'alertviews', align: 'center pl-5 hidden-sm-and-down' },
          { text: 'Views', sortable: true, value:'views', align: 'center pl-7 hidden-sm-and-down' },
          { text: '', sortable: false, align: 'right' },
        ],
        revoke: null,
        revoking: false
    }),
    methods: {
        initData(){
            
        },
        getShareNotes(item){
            let notes = [];
            let itemNotes = item.notes.split(',');
            for(let i in itemNotes){
                notes.push(this.getNoteType(itemNotes[i]));
            }
            return notes;
        },
        getNoteType(idx){
            if(idx == 1){
                return 'Medical Notes';
            }else if(idx == 2){
                return 'Child/Family Care Notes';
            }else if(idx == 3){
                return 'Pet Care Notes';
            }else if(idx == 4){
                return 'Property Notes';
            }

            return 'Other Emergency Notes';
        },
        handleRevoke(){
            let revokeId = this.revoke.uuid;
            this.revoking = revokeId;
            let request = {
                endpoint: '/notes/share/' + revokeId, 
                method: 'post',
                data: {
                    '_method' : 'delete'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your shared notes have been revoked.'
                    });
                }
            }).catch(()=>{
            }).finally(()=>{
                for(let i in this.items){
                    if(this.items[i].uuid == revokeId){
                        this.items[i].revoked = true;
                    }
                }
                this.$eventHub.$emit('refreshUser');
                this.revoke = null;
                this.revoking = null;
            });
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
        
    }
}
</script>